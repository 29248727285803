import React, { useState } from "react";

function ShortenPage() {
    const [url, setUrl] = useState("");
    const [utmString, setUtmString] = useState("");
    const [shortenedUrl, setShortenedUrl] = useState("");

    const parseUtmString = (utmString) => {
        if (!utmString) return {};
        return utmString.split("&").reduce((acc, param) => {
            const [key, value] = param.split("=");
            if (key && value) {
                acc[key] = value;
            }
            return acc;
        }, {});
    };

    const handleShorten = async () => {
        if (!url) {
            alert("Please enter a valid URL.");
            return;
        }

        const utmParameters = parseUtmString(utmString);

        try {
            const response = await fetch("/api/shorten", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                credentials: "include",
                body: JSON.stringify({ url, utm_parameters: utmParameters }),
            });

            if (response.ok) {
                const data = await response.json();
                setShortenedUrl(data.short_url);
            } else {
                alert("Failed to shorten URL. Please check your input and try again.");
            }
        } catch (error) {
            console.error("Error shortening URL:", error);
            alert("An error occurred. Please try again.");
        }
    };

    return (
        <div className="container mx-auto p-4">
            <h1 className="text-2xl font-bold mb-4">Shorten URL</h1>
            <label className="block text-gray-700 font-medium mb-2">Enter URL</label>
            <input
                type="text"
                placeholder="e.g., https://example.com"
                value={url}
                onChange={(e) => setUrl(e.target.value)}
                className="block w-full mb-4 p-2 border rounded"
            />
            <label className="block text-gray-700 font-medium mb-2">Query String (Optional)</label>
            <textarea
                placeholder="e.g., foo=bar&blube=234"
                value={utmString}
                onChange={(e) => setUtmString(e.target.value)}
                className="block w-full mb-4 p-2 border rounded"
            />
            <button
                onClick={handleShorten}
                className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
            >
                Shorten
            </button>
            {shortenedUrl && (
                <div className="mt-4">
                    <p className="font-medium">Shortened URL:</p>
                    <a
                        href={shortenedUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-500 underline"
                    >
                        {shortenedUrl}
                    </a>
                </div>
            )}
        </div>
    );
}

export default ShortenPage;

