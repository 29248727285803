import React, { useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import axios from "axios";
import Navbar from "./components/Navbar";
import LoginPage from "./pages/LoginPage";
import RegisterPage from "./pages/RegisterPage";
import ShortenPage from "./pages/ShortenPage";
import ManageUrlsPage from "./pages/ManageUrlsPage";
import StatisticsPage from "./pages/StatisticsPage"; // Correctly imported

function App() {
    // Separate states for register and login
    const [registerUsername, setRegisterUsername] = useState("");
    const [registerPassword, setRegisterPassword] = useState("");
    const [loginUsername, setLoginUsername] = useState("");
    const [loginPassword, setLoginPassword] = useState("");
    const [url, setUrl] = useState("");
    const [shortUrl, setShortUrl] = useState("");

    const handleRegister = async () => {
        try {
            const response = await axios.post("/api/register", {
                username: registerUsername,
                password: registerPassword,
            });
            alert(response.data.message);
        } catch (error) {
            alert(error.response?.data?.error || "Registration failed");
        }
    };

    const handleLogin = async () => {
        try {
            await axios.post("/api/login", {
                username: loginUsername,
                password: loginPassword,
            });
            alert("Login successful");
        } catch (error) {
            alert(error.response?.data?.error || "Login failed");
        }
    };

    const handleShorten = async () => {
        try {
            const response = await axios.post("/api/shorten", { url });
            setShortUrl(response.data.short_url);
        } catch (error) {
            alert(error.response?.data?.error || "URL shortening failed");
        }
    };

    return (
        <Router>
            <Navbar />
            <div className="bg-gray-900 min-h-screen text-gray-300">
                <Routes>
                    <Route path="/" element={<LoginPage />} />
                    <Route path="/register" element={<RegisterPage />} />
                    <Route path="/shorten" element={<ShortenPage />} />
                    <Route path="/manage" element={<ManageUrlsPage />} />
                    <Route path="/statistics/:id" element={<StatisticsPage />} />
                </Routes>
            </div>
        </Router>
    );
}

export default App;
