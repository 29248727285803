import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

function ManageUrlsPage() {
    const [links, setLinks] = useState([]);
    const [loading, setLoading] = useState(true);
    const [editableLinks, setEditableLinks] = useState({});
    const navigate = useNavigate(); // For navigating to the Statistics page

    useEffect(() => {
        const fetchLinks = async () => {
            const response = await fetch("/api/manage", {
                method: "GET",
                credentials: "include", // Include session cookies
            });
            if (response.ok) {
                const data = await response.json();
                setLinks(data.links);

                // Initialize editable links state
                const initialEditableLinks = {};
                data.links.forEach((link) => {
                    initialEditableLinks[link.id] = {
                        original_url: link.original_url,
                        utm_parameters: link.utm_parameters || "",
                    };
                });
                setEditableLinks(initialEditableLinks);
            } else {
                alert("Failed to fetch URLs. Please log in.");
            }
            setLoading(false);
        };
        fetchLinks();
    }, []);

    const handleDelete = async (id) => {
        const confirmDelete = window.confirm("Are you sure you want to delete this URL?");
        if (!confirmDelete) return;

        const response = await fetch(`/api/manage/${id}`, {
            method: "DELETE",
            credentials: "include",
        });
        if (response.ok) {
            alert("URL deleted successfully.");
            setLinks(links.filter((link) => link.id !== id)); // Remove from UI
        } else {
            alert("Failed to delete URL.");
        }
    };

const handleSave = async (id) => {
    const updatedUrl = editableLinks[id].original_url.trim();
    let updatedUtmParams = editableLinks[id].utm_parameters.trim();

    try {
        // Parse the UTM parameters into an object to validate
        const utmParamsObject = {};
        updatedUtmParams.split("&").forEach((param) => {
            const [key, value] = param.split("=");
            if (key && value) {
                utmParamsObject[key.trim()] = value.trim();
            }
        });

        // Convert back to JSON string
        updatedUtmParams = JSON.stringify(utmParamsObject);

        const response = await fetch(`/api/manage/${id}`, {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            credentials: "include",
            body: JSON.stringify({
                original_url: updatedUrl,
                utm_parameters: updatedUtmParams,
            }),
        });

        if (response.ok) {
            alert("URL updated successfully.");
            const updatedLinks = links.map((link) =>
                link.id === id
                    ? { ...link, original_url: updatedUrl, utm_parameters: updatedUtmParams }
                    : link
            );
            setLinks(updatedLinks);
        } else {
            alert("Failed to update URL.");
        }
    } catch (error) {
        alert("An error occurred while saving.");
        console.error(error);
    }
};

    const handleStatistics = (id) => {
        navigate(`/statistics/${id}`); // Navigate to the statistics page for the selected link
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <div className="container mx-auto p-4">
            <h1 className="text-2xl font-bold mb-4">Manage URLs</h1>
            {links.length === 0 ? (
                <p>No URLs found. Create a shortened URL first.</p>
            ) : (
                <table className="table-auto w-full border-collapse border border-gray-700">
                    <thead>
                        <tr>
                            <th className="border border-gray-600 p-2">Shortened URL</th>
                            <th className="border border-gray-600 p-2">Original URL</th>
                            <th className="border border-gray-600 p-2">UTM Parameters</th>
                            <th className="border border-gray-600 p-2">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {links.map((link) => (
                            <tr key={link.id}>
                                <td className="border border-gray-600 p-2">
                                    <a
                                        href={link.short_url}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="text-blue-500 underline"
                                    >
                                        {link.short_url}
                                    </a>
                                </td>
                                <td className="border border-gray-600 p-2">
                                    <input
                                        type="text"
                                        value={editableLinks[link.id]?.original_url || ""}
                                        onChange={(e) =>
                                            setEditableLinks({
                                                ...editableLinks,
                                                [link.id]: {
                                                    ...editableLinks[link.id],
                                                    original_url: e.target.value,
                                                },
                                            })
                                        }
                                        className="w-full p-1 border border-gray-500 rounded placeholder-gray-500 text-gray-400 bg-gray-800"
                                    />
                                </td>

<td className="border border-gray-600 p-2">
    <input
        type="text"
        value={editableLinks[link.id]?.utm_parameters || ""}
        onChange={(e) =>
            setEditableLinks({
                ...editableLinks,
                [link.id]: {
                    ...editableLinks[link.id],
                    utm_parameters: e.target.value.trim(),
                },
            })
        }
        className="w-full p-1 border border-gray-500 rounded placeholder-gray-300 text-gray-200 bg-gray-800"
    />
</td>

                                <td className="border border-gray-600 p-2">
                                    <button
                                        onClick={() => handleSave(link.id)}
                                        className="bg-green-600 text-white px-3 py-1 rounded hover:bg-green-700 mr-2"
                                    >
                                        Save
                                    </button>
                                    <button
                                        onClick={() => handleDelete(link.id)}
                                        className="bg-red-600 text-white px-3 py-1 rounded hover:bg-red-700 mr-2"
                                    >
                                        Delete
                                    </button>
                                    <button
                                        onClick={() => handleStatistics(link.id)}
                                        className="bg-blue-600 text-white px-3 py-1 rounded hover:bg-blue-700"
                                    >
                                        Statistics
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
        </div>
    );
}

export default ManageUrlsPage;

