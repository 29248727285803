import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

function RegisterPage() {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const navigate = useNavigate();

    const handleRegister = async () => {
        if (password !== confirmPassword) {
            alert("Passwords do not match");
            return;
        }

        const response = await fetch("/api/register", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ username, password }),
        });

        if (response.ok) {
            alert("Registration successful!");
            navigate("/"); // Redirect to login page after successful registration
        } else {
            const data = await response.json();
            alert(data.error || "Registration failed");
        }
    };

    return (
        <div className="flex justify-center items-center h-screen">
            <div className="max-w-md p-6 bg-gray-800 border border-gray-700 rounded-lg">
                <h2 className="text-xl font-bold text-white">Register</h2>
                <input
                    type="text"
                    placeholder="Username"
                    className="block w-full mt-4 p-2 bg-gray-700 border border-gray-600 rounded"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                />
                <input
                    type="password"
                    placeholder="Password"
                    className="block w-full mt-4 p-2 bg-gray-700 border border-gray-600 rounded"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
                <input
                    type="password"
                    placeholder="Confirm Password"
                    className="block w-full mt-4 p-2 bg-gray-700 border border-gray-600 rounded"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                />
                <button
                    className="w-full mt-4 p-2 bg-blue-700 text-white rounded hover:bg-blue-800"
                    onClick={handleRegister}
                >
                    Register
                </button>
            </div>
        </div>
    );
}

export default RegisterPage;

