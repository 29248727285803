import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

function Navbar() {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const navigate = useNavigate();

    const checkLoginStatus = async () => {
        const response = await fetch("/api/login-status", {
            method: "GET",
            credentials: "include", // Include cookies for session verification
        });
        if (response.ok) {
            setIsLoggedIn(true);
        } else {
            setIsLoggedIn(false);
        }
    };

    useEffect(() => {
        // Check login status when the Navbar loads
        checkLoginStatus();
    }, []);

    const handleLogout = async () => {
        const response = await fetch("/api/logout", {
            method: "POST",
            credentials: "include",
        });
        if (response.ok) {
            setIsLoggedIn(false);
            navigate("/"); // Redirect to the login page after logout
        } else {
            alert("Failed to log out. Please try again.");
        }
    };

    return (
        <nav className="bg-gray-800 border-gray-700">
            <div className="max-w-screen-xl flex items-center justify-between p-4">
                <span className="self-center text-2xl font-semibold whitespace-nowrap text-white">
                    XXIIIs
                </span>
                <ul className="flex space-x-4">
                    <li><Link to="/shorten" className="text-white hover:underline">Shorten</Link></li>
                    <li><Link to="/manage" className="text-white hover:underline">Manage URLs</Link></li>
                    {!isLoggedIn ? (
                        <>
                            <li><Link to="/" className="text-white hover:underline">Login</Link></li>
                            <li><Link to="/register" className="text-white hover:underline">Register</Link></li>
                        </>
                    ) : (
                        <li>
                            <button
                                onClick={handleLogout}
                                className="text-white hover:underline"
                            >
                                Logout
                            </button>
                        </li>
                    )}
                </ul>
            </div>
        </nav>
    );
}

export default Navbar;

