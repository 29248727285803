import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom"; // Import useNavigate
import { Bar } from "react-chartjs-2";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from "chart.js";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

function StatisticsPage() {
    const { id } = useParams();
    const navigate = useNavigate(); // Initialize useNavigate
    const [chartData, setChartData] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchStats = async () => {
            try {
                const response = await fetch(`/api/stats/${id}`, {
                    method: "GET",
                    credentials: "include",
                });
                if (response.ok) {
                    const data = await response.json();
                    console.log("Fetched stats:", data);

                    if (data.stats && data.stats.length > 0) {
                        // Prepare data for Chart.js
                        const labels = data.stats.map(
                            (entry) => entry.referrer || "No Referrer"
                        );
                        const counts = data.stats.map((entry) => entry.count);

                        setChartData({
                            labels,
                            datasets: [
                                {
                                    label: "Number of Clicks",
                                    data: counts,
                                    backgroundColor: "rgba(75, 192, 192, 0.6)",
                                    borderColor: "rgba(75, 192, 192, 1)",
                                    borderWidth: 1,
                                },
                            ],
                        });
                    } else {
                        console.warn("No statistics data available.");
                        setChartData(null);
                    }
                } else {
                    console.error("Failed to fetch stats. HTTP status:", response.status);
                }
            } catch (error) {
                console.error("Error fetching stats:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchStats();
    }, [id]);

    if (loading) {
        return <p>Loading...</p>;
    }

    if (!chartData) {
        return <p>No data available for this link.</p>;
    }

    return (
        <div className="container mx-auto p-4">
            <h1 className="text-2xl font-bold mb-4">Referrer Statistics</h1>
            <Bar
                data={chartData}
                options={{
                    responsive: true,
                    plugins: {
                        legend: {
                            position: "top",
                        },
                        title: {
                            display: true,
                            text: "Referrer Statistics",
                        },
                    },
                }}
            />
            {/* Add Back Button */}
            <div className="mt-4">
                <button
                    onClick={() => navigate(-1)} // Go back to the previous page
                    className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
                >
                    Back
                </button>
            </div>
        </div>
    );
}

export default StatisticsPage;
